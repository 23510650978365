import React, { useState, useEffect, useRef } from "react";
import "./MeasuresFilter.css";
import axios from "../../../api/axios";
import ReactPaginate from "react-paginate";
import ReactExport from "react-data-export";
import ButtonExport from "../Buttons/ButtonExport";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/fontawesome-free-solid";

const measuresFilterLink = "/mjerenja/GetAll";
const logeriLink = "/logeri/GetAll";
const groupsLink = "/grupe/GetAll";
const subgroupsLink = "/podgrupe/GetAll";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const MeasuresFilter = ({ params }) => {
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [dataExport, setDataExport] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);

  const handleSelectChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );

    // Update the selected values state with the selected options
    setSelectedValues(selectedOptions);
  };

  const klijentID = JSON.parse(localStorage.getItem("klijentID"));
  const subtitle = "Mjerenja";

  const getCurrentDateInput = () => {
    const dateObj = new Date();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();
    const shortDate = `${year}-${month}-${day}`;
    return shortDate;
  };

  const onSetDateFrom = (event) => {
    const inputDate = event.target.value;
    const dateParts = inputDate.split("-");
    const dateObj = new Date(`${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`);
    const newDate = dateObj.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    setDateFrom(newDate);
  };

  const onSetDateTo = (event) => {
    const inputDate = event.target.value;
    const dateParts = inputDate.split("-");
    const dateObj = new Date(`${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`);
    const newDate = dateObj.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    setDateTo(newDate);
  };

  const [data, setData] = useState([]);
  const [deviceValue, setDeviceValue] = useState("");
  const [deviceValues, setDeviceValues] = useState([]);

  const getData = async () => {
    const { data } = await axios.get(logeriLink);
    setData(data);
  };

  const [groupValue, setGroupValue] = useState(0);
  const [subGroupValue, setSubGroupValue] = useState(0);
  const [subGroups, setSubGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleGroupValue = (e) => {
    setGroupValue(e.target.value);
    //console.log(e.target.value);
  };

  const handleSubGroupValue = (e) => {
    setSubGroupValue(e.target.value);
    //console.log(e.target.value);
  };

  const handleDeviceValue = (e) => {
    setDeviceValue(e.target.value);
    //console.log(e.target.value);
  };

  const getGroups = async () => {
    await axios.get(groupsLink).then(function (response) {
      setGroups(response.data);
    });
  };

  const getSubGroups = async () => {
    await axios.get(subgroupsLink).then(function (response) {
      setSubGroups(response.data);
    });
  };

  const getFilteredResults = async () => {
    const queryParams = new URLSearchParams({
      datumOd: dateFrom,
      datumDo: dateTo,
      grupaID: groupValue,
      podgrupaID: subGroupValue,
    });

    selectedValues.forEach((id) => {
      queryParams.append("logerID", id);
    });

    const measuresFilterLinkWithQuery = `${measuresFilterLink}?${queryParams.toString()}`;

    try {
      setLoading(true);
      const response = await axios.get(measuresFilterLinkWithQuery);
      console.log(response.data, "data");
      setCurrentCondition(response.data);
      setDataExport(response.data);
      //generateChartData(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setErrMsg("Niste odabrali nijedan uređaj.");
    }
  };

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const [currentCondition, setCurrentCondition] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(currentCondition.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(currentCondition.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, currentCondition]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % currentCondition.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    getGroups();
    getSubGroups();
    getData();
    const inputDate = new Date().toDateString();
    const dateParts = inputDate.split("-");
    const dateObj = new Date(`${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`);
    const newDate = dateObj.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    setDateFrom(newDate);
    setDateTo(newDate);
    //console.log(newDate);
  }, []);

  const tables = selectedValues.map((deviceId, index) => {
    const deviceData = dataExport.filter(
      (item) => parseInt(item.idlogera) === parseInt(deviceId)
    );
    const tableId = `my-table-${deviceId}`;
    const chartContainerId = `chart-container-${deviceId}`;
    return (
      <>
        <div className="display-none" key={index}>
          <table className="table table-bordered" id={tableId}>
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Loger ID</th>
                <th scope="col">Uredjaj</th>
                <th scope="col">Vrijeme</th>
                <th scope="col">Temperatura</th>
                <th scope="col">Minimalna temperatura</th>
                <th scope="col">Maksimalna temperatura</th>
                <th scope="col">Vlažnost</th>
                <th scope="col">Minimalna vlažnost</th>
                <th scope="col">Maksimalna vlažnost</th>
              </tr>
            </thead>
            <tbody>
              {dataExport
                .filter(
                  (item) => parseInt(item.idlogera) === parseInt(deviceId)
                )
                .map((item) => (
                  <tr key={item.int}>
                    <td>{item.int}</td>
                    <td>{item.idlogera}</td>
                    <td>{item.loger}</td>
                    <td>
                      {item.vrijeme.slice(0, 11).replace("T", " ")}
                      {item.vrijeme.slice(11, 19)}
                    </td>
                    <td>{item.t}</td>
                    <td>{item.tmin}</td>
                    <td>{item.tmax}</td>
                    <td>{item.h}</td>
                    <td>{item.hmin}</td>
                    <td>{item.hmax}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div>
          {!loading && (
            <div className="chart-container" id={chartContainerId}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={deviceData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <YAxis domain={[0, 100]} />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="loger"
                    name="Loger"
                    stroke="#008000"
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="t"
                    name="Temperatura"
                    stroke="#FF5733"
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="h"
                    name="Vlažnost"
                    stroke="#3366FF"
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
      </>
    );
  });

  const PDFexport = async () => {
    const doc = new jsPDF({ orientation: "landscape" });

    const selectedDevices = selectedValues;

    let currentY = 10; // Initial Y position

    for (const selectedDevice of selectedDevices) {
      // Convert the React chart component to an image using html2canvas
      const chartContainer = document.getElementById(
        `chart-container-${selectedDevice}`
      );
      const chartImage = await html2canvas(chartContainer, { scale: 2 });

      // Calculate the height of the table image
      const tableContainer = document.getElementById(
        `my-table-${selectedDevice}`
      );
      const tableHeight = tableContainer.clientHeight;

      // Add the table
      doc.autoTable({
        html: `#my-table-${selectedDevice}`,
        theme: "grid",
        startY: currentY,
      });

      // Update the Y position after adding the table
      currentY = doc.autoTable.previous.finalY;

      // Check if there's enough space on the current page for both table and chart
      const chartHeight = (chartImage.height * 200) / chartImage.width; // Assuming chartWidth is 200
      const totalHeightRequired = tableHeight + chartHeight + 20; // 20 for spacing

      if (currentY + totalHeightRequired > doc.internal.pageSize.height) {
        // There's not enough space on the current page, so add the chart on a new page
        doc.addPage();
        currentY = 10; // Reset Y position to the top of the page
      }

      // Add the chart
      const imgData = chartImage.toDataURL("image/png");
      const chartX = 10; // X-coordinate
      const chartY = currentY + 10; // Place chart below the table with some spacing
      const chartWidth = 200; // Width of the chart
      doc.addImage(imgData, "PNG", chartX, chartY, chartWidth, chartHeight);

      // Update the Y position after adding the chart
      currentY += chartHeight + 20; // 20 for spacing
    }

    // Save the PDF
    doc.save(`Mjerenja_od_${dateFrom}_do_${dateTo}`);
  };

  const chartData = dataExport || []; // Make sure chartData is an array

  const firstTimestamp =
    chartData.length > 0 ? chartData[chartData.length - 1].vrijeme : "";
  const lastTimestamp = chartData.length > 0 ? chartData[0].vrijeme : "";

  const startingDate = new Date(firstTimestamp);
  const endingDate = new Date(lastTimestamp);

  const formattedStartingDate = `${startingDate.toLocaleDateString()} ${startingDate.toLocaleTimeString()}`;
  const formattedEndingDate = `${endingDate.toLocaleDateString()} ${endingDate.toLocaleTimeString()}`;
  return (
    <>
      <table className="table table-bordered display-none">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Loger ID</th>
            <th scope="col">Uredjaj</th>
            <th scope="col">Vrijeme</th>
            <th scope="col">Temperatura</th>
            <th scope="col">Minimalna temperatura</th>
            <th scope="col">Maksimalna temperatura</th>
            <th scope="col">Vlažnost</th>
            <th scope="col">Minimalna vlažnost</th>
            <th scope="col">Maksimalna vlažnost</th>
          </tr>
        </thead>
        <tbody>
          {dataExport &&
            dataExport
              .slice()
              .sort((a, b) => a.idlogera - b.idlogera)
              .map((item) => {
                return (
                  <tr key={item.int}>
                    <td>{item.int}</td>
                    <td>{item.idlogera}</td>
                    <td>{item.loger}</td>
                    <td>
                      {item.vrijeme.slice(0, 11).replace("T", " ")}
                      {item.vrijeme.slice(11, 19)}
                    </td>
                    <td>{item.t}</td>
                    <td>{item.tmin}</td>
                    <td>{item.tmax}</td>
                    <td>{item.h}</td>
                    <td>{item.hmin}</td>
                    <td>{item.hmax}</td>
                  </tr>
                );
              })}
        </tbody>
      </table>
      <div className="subtitle row">
        <div className="col-6 col-lg-10">{subtitle}</div>
        <div className="col-6 col-lg-2 ">
          {dataExport.length > 0 && (
            <>
              <div className="row">
                <div className="col-6 col-lg-6 buttons-right">
                  <button onClick={PDFexport} className="button-pdf-style">
                    PDF
                    <FontAwesomeIcon
                      className="download-icon-style"
                      icon={faDownload}
                    />
                  </button>
                </div>
                <div className="col-6 col-lg-6 buttons-right">
                  <ExcelFile
                    filename={`Mjerenja_od_${dateFrom}_do_${dateTo}`}
                    element={<ButtonExport />}
                  >
                    <ExcelSheet data={dataExport} name="Mjerenja report">
                      <ExcelColumn
                        className="excel-style"
                        label="ID"
                        value="int"
                      />
                      <ExcelColumn label="Loger ID" value="idlogera" />
                      <ExcelColumn label="Uređaj" value="loger" />
                      <ExcelColumn label="Vrijeme" value="vrijeme" />
                      <ExcelColumn label="Temperatura" value="t" />
                      <ExcelColumn label="Minimalna temperatura" value="tmin" />
                      <ExcelColumn
                        label="Maksimalna temperatura"
                        value="tmax"
                      />
                      <ExcelColumn label="Vlažnost" value="h" />
                      <ExcelColumn label="Minimalna vlažnost" value="hmin" />
                      <ExcelColumn label="Maksimalna vlažnost" value="hmax" />
                    </ExcelSheet>
                  </ExcelFile>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="measure-div">
        <span className="dates-style">
          <input
            defaultValue={getCurrentDateInput()}
            onChange={onSetDateFrom}
            type="date"
            className="date-input-style"
          />
          <input
            defaultValue={getCurrentDateInput()}
            onChange={onSetDateTo}
            type="date"
            className="date-input-style"
          />
        </span>
        <div className="mt-10">
          <span>
            <select className="measures-select" onChange={handleGroupValue}>
              <option hidden defaultValue="Odaberite grupu">
                Odaberite grupu
              </option>
              {groups.map((group, index) => {
                if (group.klijentId === klijentID) {
                  return (
                    <option value={group.id} key={index}>
                      {group.naziv}
                    </option>
                  );
                }
              })}
            </select>
            <select className="measures-select" onChange={handleSubGroupValue}>
              <option hidden defaultValue="Odaberite podgrupu">
                Odaberite podgrupu
              </option>
              {subGroups.map((subGroup, index) => {
                if (
                  subGroup.klijentId === klijentID &&
                  groupValue === subGroup.grupaId.toString()
                ) {
                  return (
                    <option value={subGroup.id} key={index}>
                      {subGroup.naziv}
                    </option>
                  );
                }
              })}
            </select>

            <div>
              <select
                className="devices-select"
                multiple
                onChange={(e) => handleSelectChange(e)}
                value={selectedValues}
              >
                <option className="devices-label-style" value="" disabled>
                  Uređaji
                </option>
                {data
                  .filter(
                    (device) =>
                      device.idklijenta === klijentID &&
                      (!groupValue ||
                        parseInt(device.grupaid) === parseInt(groupValue)) &&
                      (!subGroupValue ||
                        parseInt(device.podgrupaid) === parseInt(subGroupValue))
                  )
                  .sort((a, b) => a.id - b.id)
                  .map((device, index) => (
                    <option key={index} value={device.id}>
                      {device.naziv}
                    </option>
                  ))}
              </select>
            </div>
          </span>
        </div>
        <div className="div-button-style">
          <button onClick={getFilteredResults} className="search-button-style">
            Pretraži
          </button>
        </div>
      </div>
      {/* <div className="bg-fff">
        {dataExport && dataExport.length > 0 && (
          <div id="chart-container">
            <div className="chart-container">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={chartData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <YAxis domain={[0, 100]} />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="loger"
                    name="Loger"
                    stroke="#008000"
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="t"
                    name="Temperatura"
                    stroke="#FF5733"
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="h"
                    name="Vlažnost"
                    stroke="#3366FF"
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div className="content-center">
              <p>Od: {formattedStartingDate}</p>
              <p>Do: {formattedEndingDate}</p>
            </div>
          </div>
        )}
      </div> */}
      <div className="params-style">
        <table className="content-style table-width">
          <tbody>
            <tr className="tr-style">
              {params.map((parameter, index) => {
                return (
                  <td className="thead-style-header" key={index}>
                    {parameter}
                  </td>
                );
              })}
            </tr>
            {!loading ? (
              currentItems.length === 0 ? (
                <tr className="no-data">nema podataka</tr>
              ) : (
                currentItems
                  .slice()
                  .sort((a, b) => a.idlogera - b.idlogera)
                  .map((item, index) => (
                    <tr className="tr-style" key={index}>
                      <td className="thead-style-content">
                        {item.vrijeme.slice(0, 11).replace("T", " ")}
                        {item.vrijeme.slice(11, 19)}
                      </td>
                      <td className="thead-style-content">{item.loger}</td>
                      <td className="thead-style-content">{item.t}</td>
                      <td className="thead-style-content">{item.tmin}</td>
                      <td className="thead-style-content">{item.tmax}</td>
                      <td className="thead-style-content">{item.h}</td>
                      <td className="thead-style-content">{item.hmin}</td>
                      <td className="thead-style-content">{item.hmax}</td>
                    </tr>
                  ))
              )
            ) : (
              ""
            )}
          </tbody>
        </table>
      </div>

      {deviceValue ? (
        ""
      ) : (
        <div className={errMsg ? "errmsg" : "offscreen"}>{errMsg}</div>
      )}

      <div className="paginate-div-style">
        <ReactPaginate
          breakLabel="..."
          breakClassName="page-num"
          nextLabel="Sljedeća"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel="Prethodna"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active-page"
        />
      </div>
      <div>{tables}</div>
    </>
  );
};

export default MeasuresFilter;
